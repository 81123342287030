<template>
  <div class="loginContainer">
    <div class="loginBox flexRow justify-content-center align-item-center box-shadow-base">
      <div class="subLoginBox">
        <el-form :model="params" :rules="rules">
          <el-form-item  style="margin-bottom:76px;">
            <div class="loginTitle">
              <a>项目流水后台管理系统</a>
            </div>
          </el-form-item>
          <el-form-item prop="username">
            <el-input v-model="params.name" placeholder="请输入账号" clearable ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" placeholder="请输入密码" v-model="params.password" clearable @keyup.enter.native="login"></el-input>
          </el-form-item>
          <div class="login-btn">
            <el-button type="primary" round style="width:100%" @click="login" >登录</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import md5 from 'js-md5'
export default {
  data: function() {
    return {
      params: {
        name:'',
        password:'',
      },
      rules: {
        name: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],

      },
      change_img:'',
    };
  },
  mounted() {

  },
  methods: {
    //登录
    login(){
      if(this.params.name == ""){
        this.$message.warning("请输入账号");
        return;
      }
      if(this.params.password == ""){
        this.$message.warning("请输入密码");
        return;
      }
      let params = {
        name:this.params.name,
        password:md5(this.params.password + 'log')
      }
      axios.post(this.baseUrl + "admin/AdminUser/login",params).then(res=>{
        if(res.data.code == 1){
          let userInfo = res.data.data;
          sessionStorage.setItem("userInfo",JSON.stringify(userInfo));
          this.$message.success("登录成功");
          setTimeout(()=>{
            this.$router.back();
            sessionStorage.setItem("refresh",1)
          },2000)
        }else{
          this.$message.error("登录失败")
        }
      })
    },

  },
};
</script>

<style lang="less">
.loginContainer{
  height:100vh;
  background:url("../assets/images/bg.jpg")no-repeat center center / 100% 100%;
  position: relative;
}
.loginBox{
  width:700px;
  height:500px;
  position: absolute;
  top:50%;
  left:50%;
  margin-left:-350px;
  margin-top:-250px;
  background:#fff;
  border-radius: 5px;
  .subLoginBox{
    width:368px;
    min-width:210px;
    .loginTitle{
      font-size:24px;
      font-weight: 700;
      text-align: center;
    }
    .login-btn {
      width: 100%;
      margin-top: 60px;
    }
  }
}
</style>
